<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="新增特殊车辆"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="车牌号" v-bind="validateInfos.plateNumber">
        <a-input
          v-model:value="whitePlateNumberVo.plateNumber"
          :maxlength="10"
          placeholder="关联的车牌"
          allow-clear
        ></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, /*reactive,*/ toRefs } from "vue";
import useWhitePlateNumber from "@/hooks/member/useWhitePlateNumber";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { save } from "@/api/member/whitePlateNumberApi";
import useValidateRules from "@/hooks/useValidateRules";

const useForm = Form.useForm;
export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const { whitePlateNumberVo } = useWhitePlateNumber();
    const { specialPlateVoRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
      whitePlateNumberVo,
      specialPlateVoRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("添加新的特殊车辆?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              plateNumber: whitePlateNumberVo.plateNumber,
            };
            await save(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
    });
    return {
      ...toRefs(pros),
      disabled,
      visible,
      afterClose,
      validateInfos,
      doSubmit,
      whitePlateNumberVo,
      specialPlateVoRules,
    };
  },
};
</script>

<style scoped></style>
